<template>
      <v-row>
        <v-col
            cols="12"
            md="8"
        >
            <h2>{{ $t('termsInfo') }}</h2>
            <br><br>
            <h3>{{ $t('paymentInfo') }}</h3>
            <br>

            <h3>{{ $t('paymentInfo-oneTimeVisit') }}</h3>
            {{ CompanyName }} {{ $t('kredslob-termsInfo-paymentInfo') }}
            <br><br>

            <h3>
                {{ $t('termsInfo-yearlySubscription') }}
            </h3>
            {{ CompanyName }} {{ $t('kredslob-termsInfo-yearlySubscription') }} <a href="https://kredslob.dk/abonnement" target="_blank">kredslob.dk/abonnement</a>
            <br><br>

            <h3>{{ $t('invoicerule-exclude') }}</h3>
            {{ $t('kredslob-termsInfo-exemptionInfo1') }}
            <br>
            {{ $t('kredslob-termsInfo-exemptionInfo2') }}
            <br>
            {{ $t('kredslob-termsInfo-exemptionInfo3') }}
            <br>
            {{ $t('kredslob-termsInfo-exemptionInfo4') }}
            <br><br>
            <b>{{ CompanyName }}</b>
            <br>
            <b>{{ CompanyAddress }}</b>
            <br>
            <b>{{ CompanyCity }}</b>
            <!-- <br><br>

            <h3>{{ $t('rightOfWithdrawal') }}</h3>
            {{ $t('rightOfWithdrawal-info') }} -->
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
        CompanyName() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        CompanyAddress() {
            return process.env.VUE_APP_TERMS_COMPANY_ADDRESS
        },
        CompanyCity() {
            return process.env.VUE_APP_TERMS_COMPANY_ZIPCODE_AND_CITY
        }
    },
    created() {        
    },    
    methods: {
    }
}
</script>