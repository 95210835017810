<template>
    <v-row>
        <v-col
            cols="12"
            md="8"
        >
            <h2>{{ $t('termsInfo') }}</h2>
            <br><br>
            <h3>{{ $t('paymentInfo') }}</h3>
            {{ CompanyName }} {{ $t('termsInfo-paymentInfo') }}
            <br>
            {{ $t('termsInfo-paymentInfo2') }}
            <br>
            {{ CompanyName }} {{ $t('termsInfo-paymentInfo3') }}
            <br><br>
            <h3>{{ $t('invoicerule-exclude') }}</h3>
            {{ $t('termsInfo-exemptionInfo') }}
            <br><br>
            <b>{{ CompanyName }}</b>
            <br>
            <b>{{ CompanyAddress }}</b>
            <br>
            <b>{{ CompanyCity }}</b>
            <br><br>
            <h3>{{ $t('rightOfWithdrawal') }}</h3>
            {{ $t('rightOfWithdrawal-info') }}
        </v-col>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
        CompanyName() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        CompanyAddress() {
            return process.env.VUE_APP_TERMS_COMPANY_ADDRESS
        },
        CompanyCity() {
            return process.env.VUE_APP_TERMS_COMPANY_ZIPCODE_AND_CITY
        }
    },
    created() {        
    },    
    methods: {
    }
}
</script>