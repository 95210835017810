<template>
    <v-container
        fluid
    >
        <component :is="setTermsComponent" />

    </v-container>
</template>

<script>
import DefaultTermsInfoComponent from './TermsInfoComponents/DefaultTermsInfoComponent.vue'
import KredslobTermsInfoComponent from './TermsInfoComponents/KredslobTermsInfoComponent.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        DefaultTermsInfoComponent,
        KredslobTermsInfoComponent
    },
    computed: {
        CompanyName() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        CompanyAddress() {
            return process.env.VUE_APP_TERMS_COMPANY_ADDRESS
        },
        CompanyCity() {
            return process.env.VUE_APP_TERMS_COMPANY_ZIPCODE_AND_CITY
        },
        setTermsComponent() {
            switch (process.env.VUE_APP_TERMS_COMPANY_NAME) {
                case 'Kredsløb':
                    return 'KredslobTermsInfoComponent'
                    case 'Optidata':
                    return 'KredslobTermsInfoComponent'
                default:
                    return 'DefaultTermsInfoComponent'
            }
        },
    },
    created() {        
    },    
    methods: {
    }
}
</script>
